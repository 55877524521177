import "./Share.scss";
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useContext, useState } from "react";
import { AuthContext } from "../../context/authContext";
import { Link, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { makeRequest } from "../../axios";

const Share = () => {

  const [file, setFile] = useState(null);
  const [desc, setDesc] = useState("");

  const upload = async()=> {
    try{
        const formData = new FormData();
        formData.append("file",file)
        const res = await makeRequest.post("/upload", formData);
        return res.data
    }
    catch(err){
        console.log(err)
    }
  }

  const {currentUser} = useContext(AuthContext);
  const navigate = useNavigate()

  const queryClient = useQueryClient();

  const mutation = useMutation(
    (newPost)=>{
    return makeRequest.post("/posts", newPost);
  }, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(["posts"]);
    },
  })

  const handleClick = async (e) =>{
    e.preventDefault()
    let imgUrl ="";
    if(file) imgUrl = await upload();
    mutation.mutate({desc, img: imgUrl});
    navigate("/home");
    setDesc("");
    setFile(null)
  };

  return (
    <div className="share">
      <div className="container">
        <div className="back">
            <Link to="/home">
                <ArrowBackIosOutlinedIcon/>
            </Link>
        </div>
        <div className="share__post">
        <div className="top">
            <div className="left">
            <input type="text" placeholder={`What's on your mind ${currentUser.username}?`} onChange={(e) => setDesc(e.target.value)} value={desc} />
            </div>
            <div className="right">
                {file && <img className="file" alt="" src={URL.createObjectURL(file)}/>}
            </div>
        </div>
        <hr />
        <div className="bottom">
          <div className="left">
            <input type="file" id="file" style={{display:"none"}} onChange={(e) => setFile(e.target.files[0])}/>
            <label htmlFor="file">
              <div className="item">
                <AddRoundedIcon/>
                <span>Add Image</span>
              </div>
            </label>
          </div>
          <div className="right">
            <button onClick={handleClick}>Share</button>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Share;